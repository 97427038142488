import React, { useEffect, useState, createContext } from "react";

import { auth } from "../lib/firebase";
import { onAuthStateChanged } from "firebase/auth";

import Loading from "../Loading";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(false);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        setCurrentUser(user.uid);
        setPending(false);
        return;
      }
      setCurrentUser(false);
      setPending(false);
    });
  }, []);

  if (pending) {
    return <Loading />;
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
