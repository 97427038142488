import React, { useState, useRef } from "react";

import {
  Input,
  Button,
  Stack,
  Card,
  CardBody,
  Heading,
  Image,
  Box,
  Spinner,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";

import Preview from "./Preview";

function Uploader({ schedule }) {
  const pathFile = `https://schedules.seamarinetransport.com/uploads/${schedule}.jpg`;
  const [file, setFile] = useState(pathFile);
  const [error, setError] = useState(false);
  const [preview, setPreview] = useState(false);
  const inputRef = useRef();

  const handleClickLink = () => {
    window.open(`http://www.seamarinetransport.com/${schedule}`);
  };

  const handleClick = () => inputRef.current.click();

  const clearInput = () => (inputRef.current.value = "");

  const handleFile = async () => {
    const image = inputRef.current.files[0];

    setError(false);

    if (!["image/jpeg"].includes(image.type)) {
      setError("Select a .jpg file");
      clearInput();
      return;
    }

    if (image.size > 1048576) {
      setError("Maximum image size: 1M");
      clearInput();
      return;
    }

    let url = `https://schedules.seamarinetransport.com/php/upload.php`;

    const form_data = new FormData();
    form_data.append("image", image);
    form_data.append("shedule", schedule);

    let headers = {
      method: "POST",
      body: form_data,
    };

    try {
      setFile(null);
      const response = await fetch(url, headers);
      const message = await response.text();

      if (message !== "success") {
        setError(message);
        clearInput();
        setFile(pathFile);
        return;
      }
      clearInput();
      setFile(`${pathFile}?${Date.now()}`);
    } catch (err) {
      clearInput();
      setFile(pathFile);
      setError(err);
      return;
    }
  };

  return (
    <>
      <Card w="100%" rounded={4} boxShadow="sm">
        <CardBody>
          <Stack direction="column" spacing={4}>
            <Heading size="sm" textTransform="capitalize">
              {schedule} schedules
            </Heading>

            <Box
              w="100%"
              pb="90%"
              position="relative"
              backgroundColor="gray.100"
              border="solid 1px"
              borderColor="gray.200"
            >
              {file ? (
                <Image
                  position="absolute"
                  top={0}
                  left={0}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  objectPosition="top center"
                  src={file}
                  alt={schedule}
                  onClick={() => setPreview(true)}
                  cursor="pointer"
                />
              ) : (
                <Spinner
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="md"
                />
              )}
            </Box>

            {error && (
              <Alert status="error" variant="solid">
                <AlertIcon />
                <AlertDescription lineHeight="1.2"><b>Error:</b> {error}</AlertDescription>
              </Alert>
            )}

            <Input
              type="file"
              display="none"
              ref={inputRef}
              onChange={handleFile}
              accept="image/*"
            />

            <Stack>
              <Button
                colorScheme="blue"
                onClick={handleClick}
                rounded={4}
                isLoading={!file}
                loadingText="Uploading"
              >
                Upload Image{" "}
              </Button>
              <Button
                variant="outline"
                colorScheme="blue"
                onClick={handleClickLink}
                rounded={4}
                isLoading={!file}
                loadingText="Uploading"
              >
                View in website{" "}
              </Button>
            </Stack>
          </Stack>
        </CardBody>
      </Card>
      <Preview
        file={file}
        schedule={schedule}
        preview={preview}
        setPreview={setPreview}
      />
    </>
  );
}

export default Uploader;
