import React from "react";
import { useContext } from "react";
import { AuthContext } from "./context/Auth";

import { Routes, Route } from "react-router-dom";

import App from "./App";
import Login from "./Login";

function Router() {
  const { currentUser } = useContext(AuthContext);
  return (
    <Routes>
      <Route path="/" element={currentUser ? <App /> : <Login />} />
    </Routes>
  );
}

export default Router;
