import React from "react";
import { auth } from "./lib/firebase";
import { signOut } from "firebase/auth";
import { Button } from "@chakra-ui/react";

function SignOut() {
  function SignOut(e) {
    e.preventDefault();
    signOut(auth);
  }

  return (
    <Button
      onClick={SignOut}
      colorScheme="blue"
      rounded={4}
      variant="outline"
      size='sm'
    >
      Sign out
    </Button>
  );
}

export default SignOut;
