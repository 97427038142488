import React from "react";
import { useState } from "react";
import { auth } from "./lib/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";

import {
  Flex,
  FormLabel,
  Input,
  Button,
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Image,
  Link,
} from "@chakra-ui/react";

function Login() {
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  function errorCode(err) {
    if (err === "auth/user-not-found" || err === "auth/wrong-password") {
      return "Incorrect username or password.";
    }

    if (err === "auth/invalid-email") {
      return "Invalid email";
    }

    if (err === "auth/missing-password") {
      return "Please enter your password";
    }

    if (err === "auth/internal-error") {
      return "Internal error";
    }

    return err;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    setAlertMessage(null);
    setAlert(false);
    const { email, password } = event.target.elements;
    try {
      await signInWithEmailAndPassword(auth, email.value, password.value);
    } catch (error) {
      signOut(auth);
      setLoading(false);
      const message = errorCode(error.code);
      setAlertMessage(message);
      setAlert(true);
    }
  }

  return (
    <>
      <Flex
        height="100vh"
        alignItems="center"
        justifyContent="center"
        background="gray.100"
        py={6}
        px={2}
        flexDirection="column"
      >
        <Box w="200px" mb={6}>
          <Image src="smt-logo-vertical-fcolor.png" alt="SMT" w="100%" />
        </Box>

        <Flex
          direction="column"
          background="white"
          p={10}
          rounded={4}
          boxShadow="lg"
          w="380px"
        >
          <form onSubmit={handleSubmit}>
            <Flex direction="column">
              <FormLabel fontSize="sm">Email:</FormLabel>
              <Input
                placeholder="Enter your email"
                type="email"
                name="email"
                size="md"
                mb={3}
                rounded={4}
                maxLength={70}
                autoComplete="username"
              />
              <FormLabel fontSize="sm">Password:</FormLabel>
              <Input
                type="password"
                placeholder="********"
                name="password"
                size="md"
                mb={3}
                rounded={4}
                maxLength={16}
                autoComplete="current-password"
              />
              <Button
                colorScheme="blue"
                type="submit"
                loadingText="Verifying"
                isLoading={loading}
                rounded={4}
              >
                Sign in
              </Button>
              {alert && (
                <Alert status="error" mt={3}>
                  <AlertIcon />
                  <AlertDescription lineHeight="1.2">
                    <b>Error:</b> {alertMessage}
                  </AlertDescription>
                </Alert>
              )}
            </Flex>
          </form>
        </Flex>
        <Link
          href="http://www.seamarinetransport.com/"
          isExternal
          mt={6}
          fontSize="sm"
          color="gray.500"
        >
          www.seamarinetransport.com
        </Link>
      </Flex>
    </>
  );
}

export default Login;
