import React, { useEffect } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";

function Preview({ file, schedule, preview, setPreview }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    if (preview === true) {
      onOpen();
    }
  }, [preview, onOpen]);

  const handleClose = () => {
    onClose();
    setPreview(false);
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose} size="xl" rounded={4}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textTransform="capitalize" fontSize="md">{schedule} Schedules</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image width="100%" src={file} alt={schedule} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Preview;
