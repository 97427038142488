import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAzh1mWdqfEBTCnKYRhCq-na0EcV0ahKv0",
  authDomain: "websites-56866.firebaseapp.com",
  projectId: "websites-56866",
  storageBucket: "websites-56866.appspot.com",
  messagingSenderId: "72762574392",
  appId: "1:72762574392:web:d29813b5c4532c443ac8cd",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth };
