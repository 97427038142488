import React from "react";
import Uploader from "./Uploader";
import { Grid, Box, Flex, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import SignOut from "./SignOut";

function App() {
  return (
    <>
      <Grid
        padding="1rem 1rem 3rem 1rem"
        templateColumns="minmax(auto,900px)"
        justifyContent="center"
        gap="3rem"
      >
        <Box
          width="auto"
          background="white"
          rounded={4}
          display="flex"
          alignItems="center"
          padding="1rem"
          gap="1rem"
          justifyContent="space-between"
          boxShadow="sm"
        >
          <Alert background="white">
            <AlertIcon />
            <AlertDescription lineHeight="1.2">The images must be in JPG format and have a maximum size of 1MB.</AlertDescription>
          </Alert>
          <Flex gap={2}>
            <SignOut />
          </Flex>
        </Box>
        <Grid
          width="100%"
          templateColumns="repeat(auto-fit,minmax(22rem,1fr))"
          gap="1rem"
          alignItems="center"
          justifyContent="center"
          margin="auto"
        >
          <Uploader schedule="america" />
          <Uploader schedule="asia" />
        </Grid>
      </Grid>
    </>
  );
}

export default App;
